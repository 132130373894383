/*
  Website-PF CCS

  BASE STYLES
*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrap {
  min-height: 100vh;
  position: relative;
}

.app {
  display: flex;
  flex-direction: column;
  color: black;

}

.header {
  color: black;
}

h1, .h1 {
  font-size: 1.5rem;
}

.content {
  /*margin-left: 1%;*/
  /*margin-right: 1%;*/
  /*margin-top: 10px;*/
  background-color: #FFFFFF;
  text-align: center;
}

code {
  font-family: "Courier New", monospace;
}

.main-link-style {
  text-decoration: none;
  color: #ddffaa;
  font-family: "Century Gothic", "Arial", sans-serif;
  font-weight: bold;
}

.main-link-style:Hover {
  text-decoration: none;
  color: #ddffaa;
}

.main-link-style-dark {
  text-decoration: none;
  color: #343a40;
  font-family: "Century Gothic", "Arial", sans-serif;
  font-weight: bold;
}

.secondary-link-style, .secondary-link-style:active {
  text-decoration: none;
  color: #ddffaa;
  font-family: "Arial", sans-serif;
}

.secondary-link-style:hover {
  text-decoration: none;
  color: #ddffaa;
}

.secondary-link-style-background {
  background-color: #343a40;;
}

.main-navbar {
  background-color: #e8e8e8;
}

.secondary-navbar {
  background-color: #ddffaa;
  z-index: 1000;
  color: #000000;
  text-align: center;
}

/*
  FOOTER STYLES
*/
.app-footer {
  bottom: 0;
  width: 100%;
  position: absolute;
  line-height: 25px;
  z-index: 2000;
  font-size: 0.9em;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  background-color: #343a40;
  color: white;
}

/*
 POST STYLES
*/
.post {
  max-width: 1150px;
  margin-left:1%;
  margin-right: 1%;
}

.post-title {
  font-family: "Century Gothic", "Arial", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  display: block;
  width: 100%;
}

.post-title img {
  min-width:100%;
  min-height:100%;
  max-width:100%;
  max-height:500px;
  object-fit: cover;

}



.post .post-body {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  text-align: left;
  line-height: 1.6;
}

.post-body p {
  margin-bottom: 2rem;
}

.post .post-body h1, h2, h3, h4, h5, h6 {
  font-family: "Arial", sans-serif;
}

.photo-credit {
  font-size: 0.6rem;
  text-align: right;
  padding-right: 5%;
}

.post-list-body {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  text-align: left;
  line-height: 1.2;
}

.post-list-text {
  font-weight: lighter;
}

.post-list-item {
  background-color: #fafafa;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-top: solid 1px #d6d4d4;
}

.post-list-img {
  margin-right: 15px;
  float: left;
}

/*
POPUP MODAL STYLES
*/
.modal-header {
  background-color: #343a40;
  color: #ddffaa;
}


/*
COOKIE POLICY STYLES
*/
#cookiePolicyNotification {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2500;
  background-color: #ddffaa;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.cookieLink {
  text-decoration: underline;
  color: #343a40;
}

.cookieBtn {
  background-color: #343a40;
  color: #FFFFFF;
}
